import React, { FC, useRef } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { st, classes } from './Widget.st.css';
import { FormControllerActions } from '../Actions/actions';
import { FormActionsProvider } from '../Hooks/useFormActions';
import { FormRef, SubmissionResponse } from '@wix/forms-ui/types';
import FormInputs from './FormInputs/FormInputs';
import { Login } from './Login/Login';
import { FormState } from '../../../utils/state/initialStateFactory';
import Details from './Details/Details';
import { Header } from './Header/Header';
import BookButton from './BookButton/BookButton';
import Payment from './Payment/Payment';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import EmptyStatePage from './EmptyStatePage/EmptyStatePage';
import { GeneralErrorType } from '../../../types/types';

export type FormComponentActions = { submitForm?: () => SubmissionResponse };
export type FormActions = FormControllerActions & FormComponentActions;

export type ControllerProps = {
  actions: FormControllerActions;
} & FormState;

const Widget: FC<WidgetProps<ControllerProps>> = ({
  actions,
  service,
  slotAvailability,
  businessInfo,
  numberOfParticipants,
  pricingPlanDetails,
  isPricingPlanInstalled,
  selectedPaymentOptionId,
  hasCoupon,
  couponDiscount,
  memberDetails,
  errors,
  showCouponInput,
}) => {
  const formRef = useRef<FormRef>();
  const submitForm = () => formRef?.current?.submit();
  const showLogin = !memberDetails;

  const slot = slotAvailability?.slot!;
  const dateRegionalSettingsLocale = businessInfo?.dateRegionalSettingsLocale!;
  const empty_state_errors = [
    GeneralErrorType.INVALID_SERVICE_ID,
    GeneralErrorType.INVALID_SLOT_AVAILABILITY,
    GeneralErrorType.INVALID_CATALOG_DATA,
  ];
  const shouldShowEmptyStatePage = () =>
    empty_state_errors.some((error) => errors.includes(error));

  const dateAndTimeFormatter = new DateTimeFormatter(
    dateRegionalSettingsLocale,
  );

  return shouldShowEmptyStatePage() ? (
    <EmptyStatePage />
  ) : (
    <FormActionsProvider value={{ ...actions, submitForm }}>
      <div className={st(classes.root, {})}>
        <div className={classes.backButton}>Back</div>
        <div className={classes.body}>
          <div className={classes.formWrapper}>
            <Header {...service.formHeader} />
            {showLogin ? <Login /> : null}
            <FormInputs
              formSchema={service.formSchema}
              formRef={formRef}
              memberDetails={memberDetails}
            />
          </div>
          <div className={classes.sidebar}>
            <Details
              service={service}
              startDate={slot.startDate!}
              endDate={slot.endDate!}
              dateRegionalSettingsLocale={dateRegionalSettingsLocale}
            />
            <Payment
              errors={errors}
              servicePayment={service.payment}
              numberOfParticipants={numberOfParticipants}
              pricingPlanDetails={pricingPlanDetails!}
              dateRegionalSettingsLocale={dateRegionalSettingsLocale}
              isPricingPlanInstalled={isPricingPlanInstalled}
              selectedPaymentOptionId={selectedPaymentOptionId!}
              dateAndTimeFormatter={dateAndTimeFormatter}
              hasCoupon={hasCoupon}
              couponDiscount={couponDiscount!}
              showCouponInput={showCouponInput}
            />
            <BookButton service={service} />
          </div>
        </div>
      </div>
    </FormActionsProvider>
  );
};

export default Widget;
